.container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.containernew {
    padding: 20px;
    width: 460px;
    height: auto;
    border-radius: 5px;
    margin: auto;
    /*background-color: lavender;*/
    /* background-color: #1976d242; */
    /* background: ; */
    /* position: absolute; */
    /* top: 0; */
    /* right: 0; */
    /* bottom: 0; */
    /* left: 0; */
    /* border: 1px solid black; */
}


.box {
    position:absolute;
    top: 300px;
    left: 46%;
    width: 350px;
    height: 200px;
}
